import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero-adjusted/hero"
import aclVid from "../images/acl-tears/acl-vid.jpg"
import BrownDog from "../images/acl-tears/brown-hairy-dog.jpg"
import tibialSloped from "../images/acl-tears/tibial-sloped-notext.jpg"
import slidingRad from "../images/acl-tears/sliding-rad.gif"
import brownDogSitting from "../images/acl-tears/brown-dog-sitting.jpg"
import Arthroscopy from "../images/acl-tears/arthrscopy-set-up.jpg"
import dogWithLeash from "../images/acl-tears/dog-with-leash.png"
import { LazyLoadImage } from "react-lazy-load-image-component"
import TPLOVideoThumbnail from "../images/tplo/canine_acl_tear_tplo.png"
import VideoModal from "../components/videoModal/videoModal"

// import cssIframe from "../components/styleIframe.css"

import { Container, Row, Col } from "reactstrap"

const Acl = ({ location }) => {
  // console.log(location.pathname)
  // useEffect(() => {
  //   window.onload = function() {
  //     let myiFrame = document.getElementById("widthIssue")
  //     let doc = myiFrame.contentDocument
  //     doc.body.innerHTML =
  //       doc.body.innerHTML +
  //       "<style>#player{max-width:unset;}.player{max-width:unset}</style>"
  //   }
  // }, [])
  return (
    <Layout>
      <Seo title="ACL Tears" />
      <div>
        <Hero
          videoImg={aclVid}
          pageTitle="ACL Tears"
          location={location}
          scaled={true}
        />
      </div>
      {/* <div className="about-wrapper py-5"> */}
      <Container className="acl-container mb-5">
        <Row className="mb-4 pb-5">
          <Col md={12}>
            <h2 className="mb-5 text-blue">Canine ACL Tears</h2>
            <Row>
              <Col md={7}>
                <h4>Overview</h4>
                <p className="pt-3">
                  Cranial cruciate ligament tears, also known as anterior
                  cruciate ligament (ACL) tears, are the most prevalent
                  orthopedic condition seen in dogs. The cause of this
                  ever-increasing problem remains elusive, although chronic
                  biomechanical wear associated with the canine tibial plateau
                  slope seems to be the common denominator. Unlike humans,
                  canine ACL tears are typically a chronic degenerative process
                  rather than acute traumatic injury. Left untreated, canine ACL
                  tears often lead to a high incidence opposite side tearing,
                  meniscal pathology and osteoarthritis.
                </p>
                <p className="mb-0">
                  All dog breeds develop ACL tears. Limping and sitting to the
                  side are the main clinical signs. Tentative diagnosis is based
                  on palpation and radiographs. Definitive diagnosis should
                  always be confirmed by an arthroscopic examination.
                </p>
              </Col>
              <Col md={5} className="text-center">
                <LazyLoadImage
                  effect="blur"
                  src={BrownDog}
                  alt="Brown Hairy Dog"
                  className="img-fluid float-right floatClass"
                  style={{ width: "300px" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="py-4">
          <Col md={7}>
            <h4 className="mb-4">How Canine ACL Tears Occur</h4>
            <p className="mb-5">
              Canine ACL tears are the result of chronic biomechanical wear
              rather than acute trauma. Unlike humans, dogs have a sloping
              tibial plateau (figure 1). Because of the canine sloping tibial
              plateau, the femur slides down and back the sloping tibial plateau
              during weight bearing resulting in biomechanical stress and
              ultimately degeneration and tearing of the ACL (figure 2). In
              dogs, the ACL almost always tears or degenerates gradually over
              time due to this chronic biomechanical stress (figure 3). This
              degenerative process is well documented via arthroscopy (figure
              4).
            </p>
            <div className="d-flex justify-content-between mb-5">
              <Row>
                <Col md={6}>
                  <LazyLoadImage
                    effect="blur"
                    src={tibialSloped}
                    alt="Tibial Sloped"
                    style={{ width: "100%" }}
                    className="tibial-sloped"
                  />
                  <p
                    style={{
                      fontFamily: "Caveat",
                      fontSize: 25,
                      marginTop: "10px",
                    }}
                  >
                    Figure 1: Tibial Plateau slope
                  </p>
                </Col>
                <Col md={6}>
                  <LazyLoadImage
                    effect="blur"
                    src={slidingRad}
                    alt="Sliding Rad"
                    style={{ width: "100%" }}
                    className="tibial-sloped"
                  />
                  <p
                    style={{
                      fontFamily: "Caveat",
                      fontSize: 25,
                      marginTop: "10px",
                    }}
                  >
                    Figure 2: Sliding Motion Leading to ACL tears
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={5}>
            <div className="text-right ml-xl-5">
              <div className="embed-container letterbox">
                <iframe
                  src="https://player.vimeo.com/video/440720428"
                  width="100%"
                  height="300"
                  cellSpacing="0"
                  frameBorder="0"
                  allow="fullscreen"
                  allowFullScreen
                  title="How Canine ACL Tears Occur"
                />
              </div>
            </div>
            <div className="my-4 text-right">
              <VideoModal
                thumbnail={TPLOVideoThumbnail}
                src="https://player.vimeo.com/video/451874740"
              />
              <p
                className="text-right mb-3"
                style={{ fontFamily: "Caveat", fontSize: 29 }}
              >
                Figure 3
              </p>
            </div>
            <div className="text-right ml-xl-5">
              <div className="embed-container letterbox">
                <iframe
                  src="https://player.vimeo.com/video/440723568"
                  width="100%"
                  height="300"
                  frameBorder="0"
                  allow="fullscreen"
                  allowFullScreen
                  title="ACL Disease Progression in dogs"
                ></iframe>
              </div>
            </div>
            <p
              className="text-right mb-0"
              style={{ fontFamily: "Caveat", fontSize: 29 }}
            >
              Figure 4
            </p>
          </Col>
        </Row>
        <Row className="my-4 py-2">
          <Col md={7}>
            <h4>Clinical Signs & Diagnosis</h4>
            <p>
              The clinical signs seen in dogs with ACL tears are quite variable,
              from subtle lameness to complete non-weight bearing. Subtle
              lameness and “off-weighting” the leg are often seen early in the
              degenerative, tearing process. However, many dogs have persistent,
              moderate to severe lameness. The degree of lameness is influenced
              by the progressive osteoarthritis seen with ACL pathology, degree
              of tearing and concurrent meniscal tears.
            </p>
            <p>
              Almost all dogs with ACL problems sit to the side, even when
              sitting for a treat. Sometimes the lameness comes on quickly but
              often it’s a gradual progressive problem. It is not uncommon for
              the problem to wax and wane for weeks or even months.
            </p>
            <div className="d-flex">
              <div style={{ width: "100%" }}>
                <LazyLoadImage
                  effect="blur"
                  src={brownDogSitting}
                  alt="Sitting brown dog"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <p>
              Palpation is accurate in diagnosing canine ACL tears in dogs with
              acute complete tears. However, palpation alone is inaccurate in
              nearly half of all dogs with ACL problems due to gradual,
              incomplete tearing and the ensuing scar tissue that develops as
              the ligament degenerates. The formation of scar tissue is also
              termed “joint fibrosis” and while this tissue does help stabilize
              the joint, it also decreases range of motion an is a source of
              chronic pain.
            </p>
            <p>
              Radiographs (X-rays) are helpful in ACL tear diagnosis. In some
              cases, the side view X-ray will actually show the displaced femur.
              Other radiographic signs commonly seen in dogs with ACL tears
              include joint effusion and bone spur (osteophyte) formation.
            </p>
            <p>
              Definitive diagnosis of canine ACL tears should be made via an
              arthroscopic examination at the onset of surgery. Arthroscopy is
              extremely accurate and minimally invasive. In our opinion no open
              surgical procedure should be performed prior to obtaining an
              accurate, non-invasive arthroscopic diagnosis.{" "}
            </p>
          </Col>
          <Col md={5}>
            <div className="text-right ml-xl-5">
              <div className="embed-container letterbox">
                <iframe
                  src="https://player.vimeo.com/video/440721634"
                  width="100%"
                  height="300"
                  frameBorder="0"
                  allow="fullscreen"
                  allowFullScreen
                  title="Canine ACL Tears - Clinical Signs and Diagnosis"
                />
              </div>
            </div>
            <div className="text-right mt-3 ml-xl-5">
              <div className="embed-container letterbox">
                <iframe
                  src="https://player.vimeo.com/video/440721724"
                  width="100%"
                  height="300"
                  frameBorder="0"
                  allow="fullscreen"
                  allowFullScreen
                  title="Canine ACL Sit Test"
                />
              </div>
            </div>
            <div className="text-right mt-3 ml-xl-5">
              <div className="embed-container letterbox">
                <iframe
                  src="https://player.vimeo.com/video/440722264"
                  width="100%"
                  height="300"
                  frameBorder="0"
                  allow="fullscreen"
                  allowFullScreen
                  title="Anthroscopy - Canine ACL Tear"
                />
              </div>
            </div>
            <div className="mt-3 text-right">
              <LazyLoadImage
                effect="blur"
                src={Arthroscopy}
                alt="Arthroscopy"
                style={{ width: "100%" }}
                className="imageWidth"
              />
            </div>
          </Col>
        </Row>
        <Row className="my-3 pt-2">
          <Col md={7}>
            <h4 className="mb-4">Treatment Options</h4>
            <p>
              <b>Tibial Plateau Leveling Osteotomy (TPLO)</b> is by far, the
              technique that the majority of veterinary surgeons agree yields
              the most consistent, long-term positive results in dogs with ACL
              tears. Colorado Canine Orthopedics has performed tens of thousands
              of TPLO surgeries over the past 20 years.
            </p>
            <Link to="/tplo/" className="btn mb-4">
              LEARN MORE ABOUT TPLO
            </Link>
            <p>
              <b>Non-surgical treatments</b> are usually unsuccessful in canine
              ACL tears due to chronic biomechanical stress related to the
              canine stifle (knee) anatomy; specifically, the sloping tibial
              plateau. Custom splinting has been advocated by folks that make
              and market these devises, however, in our opinion (and experiences
              from seeing these patients), this option is expensive,
              frustrating, and ineffective. Furthermore, the inability of
              splinting to provide stability often results in subsequent
              meniscal tearing. Regenerative medicine specifically stem cell
              therapy and platelet rich plasma (PRP) have also been used
              unsuccessfully to treat ACL tears. Colorado Canine Orthopedics is
              a nationally known authority in veterinary regenerative medicine
              and in our opinion the use of these treatments are ineffective and
              lack of scientific supportive evidence.
            </p>

            <p>
              <b>Surgical options</b> are broken down into replacement
              techniques and biomechanical repairs. Fishing line and like
              materials, have been used to replace torn ACLs for decades. Newer,
              more sophisticated materials are currently available but
              consistent long-term security is still questionable. All surgeons
              at CCOR have performed replacement techniques, for one reason or
              another, and in our experience replacement techniques often fail.
              Recent studies have shown replacement techniques do not provide
              adequate joint stability and are the least effective surgical
              method in restoration of normal joint biomechanics.
            </p>

            <p>
              Biomechanical repairs address the underlying biomechanical cause
              of canine ACL tears and the TPLO procedure is the most widely
              performed and accepted. Sophisticated fluoroscopic (video X-ray)
              studies have examined dogs with normal stifle joints, dogs with
              ACL tears and dogs that have undergone current surgical techniques
              and found the TPLO is the best procedure in restoring normal joint
              biomechanics.
            </p>
            <Link to="/tplo/" className="btn">
              LEARN MORE ABOUT TPLO
            </Link>
          </Col>
          <Col md={5}>
            <div className="text-right mt-3">
              <div className="ml-xl-5">
                <div className="embed-container letterbox">
                  <iframe
                    src="https://player.vimeo.com/video/440722911"
                    width="100%"
                    height="300"
                    frameBorder="0"
                    allow="fullscreen"
                    allowFullScreen
                    title="Canine ACL Tears - Treatment Options"
                  />
                </div>
              </div>

              <img
                src={dogWithLeash}
                alt="Acl vid 1"
                className="dog-acl img-fluid"
              />
            </div>
          </Col>
        </Row>
      </Container>
      {/* </div> */}
    </Layout>
  )
}

export default Acl
